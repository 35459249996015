import {findProfileByEmail, registerProfile} from "../api/service";

export const saveResWidget = (message, setState) => {
    const save = {
        message: message,
        type: 'widget',
        id: new Date().getTime()
    }

    setState(prevState => ({
        ...prevState,
        messages: [...prevState.messages, save]
    }))
}

export const loadingStart = () => {
    const loading_div = document.createElement('div');
    const loading_img = document.createElement('img');
    loading_img.src = 'assets/images/loader.gif';
    loading_div.className = 'loading-start';
    loading_div.append(loading_img);
    document.body.append(loading_div);
}

export const removeLoadingStart = () => {
    const loading_point = document.querySelectorAll('.loading-start');
    loading_point && loading_point.forEach(e => e.remove());
}

export const loadingPoints = () => {
    const loading_point = document.createElement('span');
    const next_point = document.createElement('span');
    loading_point.className = 'loading-points';
    loading_point.append(next_point);
    document.body.append(loading_point);
}

export const removeLoadingPoints = () => {
    const loading_point = document.querySelectorAll('.loading-points');
    loading_point && loading_point.forEach(e => e.remove());
}

export const capitalizeFirstLetter = (inputString) => {
    if (inputString == null)
        return;

    if (inputString.length === 0) {
        return inputString;
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export const registerCustomer = async (firstName, lastName, email, age) => {

    let customer_id

    const data = {
        age: age || null,
        "app": "abbi",
        email: email,
        firstname: firstName || null,
        lastname: lastName,
        group_id: 1,
        pwd: "effimetrix"
    }

    let res = await findProfileByEmail(email)
    if (res.items.length) {
        customer_id = res.items[0].id
    } else {
        const tmp = await registerProfile(data)
        customer_id = tmp.id
    }

    return customer_id

    // const token = await getToken(customer_id)
    // return token
    //updateCustomer('token', token.token)

}
