import axios from 'axios'

const base_url = 'https://api.mychatassist.fr';

export const getChatbot = async (id) => {

  try {
    const result = await axios({
      method: 'get',
      url: `${base_url}/chatbot/${id}`
    })
    return result.data
  } catch (error) {
    // console.error(error)
  }

}

export const createQt = async (id) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${base_url}/chat`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        'chatbot_id' : id
      }
    });
    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const postQuestions = async (idQt, qt) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${base_url}/chat/${idQt}/message`,
      data: qt
    })
    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const postQuestionsCoiffirst = async (idQt, qt) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${base_url}/chat/${idQt}/coiffirst/rdv/message`,
      data: qt
    })
    return result.data
  } catch (error) {
    console.error(error)
  }

}
