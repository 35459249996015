import React, {useEffect, useState} from 'react'
import './videoIntro.css'
import {Langage} from "../../language";
import {appLanguage, titleNormalized} from "../../select";
import {loadingStart, removeLoadingStart} from '../../utils/utils';

const getText = (key) => {
    return Langage[key][appLanguage];
}

const VideoIntro = ({handleCloseVid, handleReady}) => {

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    setShowLoading(true);
    handleVideo(localStorage.getItem('video_intro') || `/assets/videos/intro-${titleNormalized}-${appLanguage}.mp4`, (localStorage.getItem('video_text') || getText('start')), (+localStorage.getItem('ai_video_duration') || 10200), localStorage.getItem('mute'));
  }, []);

  const goToNext = () => {
    setTimeout(async () => {
      setShowLoading(false);
      handleReady(true)
    }, 200)
  }

    const handleVideo = (src, text, time, mute) => {
        let video = document.getElementById('vid');
        let video_text_span = document.getElementById('vid_text_span');
        let container_video = document.getElementById('container_vid')

        if(mute === 'true')
            video.muted = true;

        video_text_span.textContent = text
        container_video.style.transform = 'translateY(0)';

        loadingStart()
        const r = new XMLHttpRequest();
        r.onload = function() {
            removeLoadingStart();
            video.src = URL.createObjectURL(r.response);
            video.play();
            setTimeout(() => {
                container_video.style.transform = 'translateY(1000%)';
                goToNext();
            }, time)
        };
        r.open("GET", src);
        r.responseType = "blob";
        r.send();
    }

    return (
        <div>
            <div id="container_vid">
                <video playsInline id="vid">
                    <source type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className={'vid-text-talk'}>
                    <img src="/assets/images/ai_talking.png" alt=""/>
                    <div id="vid-text">
                        <span id="vid_text_span"></span>
                    </div>
                </div>
              {showLoading && <span className={'loading-points'} style={{bottom: '20px'}}><span></span></span>}
            </div>
        </div>
    )
}

export default VideoIntro
