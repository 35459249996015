import React, { useState, useEffect } from 'react'
import { Box, Dialog, DialogContent } from '@mui/material';
import './offers.css';
import { Langage } from "../../language";
import { appLanguage, titleNormalized } from "../../select";
import OneButton from '../oneButton/oneButton';

const Offers = (props) => {
    const [num, setNum] = useState(null);
    const [offreOpen, setOffreOpen] = useState(false);
    const [showNext, setShowNext] = useState(false);

    const img = titleNormalized === 'chatbot-hair' ? props.state.hairType : props.state.skinType

    const getText = (key) => {
        return Langage[key][appLanguage]
    }

    const playAnim = () => {
        for (let i = 0; i < 101; i++) {
            setTimeout(() => {
                setNum(i);
                if (i === 3) {
                    document.querySelector('#offre-prep').scrollIntoView();
                }
                if (i === 20) {
                    setOffreOpen(true);
                }
                if (i === 90) {
                    setOffreOpen(false);
                }
                if (i === 100) {
                    showVideo();
                }
            }, 200 * i);
        }
    }

    const showVideo = () => {
        setShowNext(true);
        setTimeout(() => {
            document.querySelector('#offre-prep-end').scrollIntoView();
            // handleVideo(`assets/videos/video-7-${titleNormalized}-${appLanguage}.mp4`, null, 4000)
        }, 800)
    }

    useEffect(() => {
        setShowNext(false);
        // handleVideo(`assets/videos/video-4-${titleNormalized}-${appLanguage}.mp4`, null, 11000)
        setTimeout(() => {
            playAnim();
        }, 11000);
    }, [])

    return (
        num && <Box>
            {
                !showNext &&
                <div className={'pub-img'}>
                    <span className={'num'}>{getText('presc_on_way')}</span>
                    <div className="progress-bar">
                        <span>{num} %</span>
                    </div>
                    <progress value={num} max="100"></progress>
                    <div className={'offre-prep'} id={'offre-prep'}>
                        <span>{getText('see_our_offer')}</span>
                    </div>

                </div>
            }
            {
                offreOpen && <Dialog open={offreOpen}>
                    <DialogContent>
                        <div className={'selfy-img pimg'}>
                            <div className={'close'} onClick={() => {setOffreOpen(false)}}>x</div>
                            <img src={`/assets/images/pub/${titleNormalized}/` + img + '.png'} alt="pub" className={'pub'} />
                        </div>
                    </DialogContent>
                </Dialog>
            }
            {
                showNext && <div id={'offre-prep-end'}>
                    <div className={'offre-prep'}>
                        <span>{getText('i_have_products')}</span>
                    </div>
                    {/* <Button className={'offer-btn'} variant="outlined" onClick={ () => {goToMarketPlace(props.state)}}>{getText('see_ma_selection')}</Button> */}
                    {!titleNormalized.includes('abbi') && <OneButton payload={{url: props.payload.url, content: getText('see_ma_selection')}}/>}
                <br/>
                    {titleNormalized.includes('abbi') && <OneButton payload={{urlAbbi: props.payload.urlAbbi, content: getText('discover_tm')}}/>}
                </div>
            }
        </Box >
    )
}

export default Offers
