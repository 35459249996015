import React from 'react'
import { appLanguage, titleNormalized } from "../select";
import { Langage, LangageError, LangageHair } from "../language";
import {
  loadingPoints,
  loadingStart,
  removeLoadingPoints,
  removeLoadingStart
} from '../utils/utils.js';

import {createQt, postQuestions} from "../api/service-mca";

const getText = (key) => {

  if (titleNormalized.includes('hair')) {
    return LangageHair[key][appLanguage];
  }
  return Langage[key][appLanguage];
}

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const input = document.getElementsByClassName("react-chatbot-kit-chat-input")[0] || {};

  let loading = false;

  const next = async (message) => {
    if(titleNormalized === 'coiffirst-rdv' && message === getText('start_diag')) {
      loading = true;
      loadingStart();
    }

    let result, id, obj;

    try {
      loadingPoints()

          id = localStorage.getItem("chatId")
          if (!id) {
            result = await createQt(JSON.parse(localStorage.getItem('chatbot'))?.id)
            id = result.id
            localStorage.setItem("chatId", result.id)
          }
          document.querySelector('.chatId').innerText = localStorage.getItem("chatId");
          obj = { "content": message }
          result = await postQuestions(id, obj);

      removeLoadingPoints();

      if(result.response) {
        if(loading) {
          loading = false;
          removeLoadingStart()
        }
        setTimeout(() => {
          const lastChild = document.querySelectorAll('.react-chatbot-kit-chat-bot-message-container')
          lastChild[lastChild.length-1].scrollIntoView({behavior: "smooth"})
        }, 1000)
      }

      if (!result || !result.response) {
        createBotMessage(LangageError['chat_error'][appLanguage], {});
      } else {
        createBotMessage((result.response || '').replaceAll('*', ' ').replaceAll('#', ' '), result.options)
      }

    } catch (error) {
      createBotMessage(error.toString());
      removeLoadingPoints();
    }
  }

  const createBotMessage = (msg, options = {}) => {
    input.readOnly = false
    const message = createChatBotMessage(
      msg,
        Object.assign({
          withAvatar: false,
        }, options)
    );
    addMessageToState(message)
  };

  const addMessageToState = (message) => {
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { next, createBotMessage, addMessageToState },
        });
      })}
    </div>
  );
};

export default ActionProvider;


