import React, { useEffect } from 'react'
import './myHeader.css'
import {titleNormalized, appLanguage} from "../../select";
import {AppName} from "../../language";


const MyHeader = (props) => {

  const getTextApp = (key) => {
    return AppName[key][appLanguage];
  }

  const getAppName = (app) => {
      return getTextApp(titleNormalized);
  }

  return (
        <div className={'header'}>
          <div><img src={JSON.parse(localStorage.getItem('chatbot'))?.modules?.chatbot?.logo} alt=""/></div>
          <div className={'botName'}><img src="/assets/images/chat-active.png" alt=""/> {getAppName(titleNormalized)}</div>
          <div className={'botImg'}><img src={localStorage.getItem('avatar') || ("/assets/images/profil-" + titleNormalized + ".png")} id={'botImg'}/></div>
        </div>
  )
}

export default MyHeader
